.tsf-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Scripter;
  text-transform: uppercase;
  font-size: inherit;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: 0.1s linear;
}

.tsf-button-primary {
  background-color: var(--primary);
  color: var(--white);
}

.tsf-button-third {
  background-color: var(--third);
  color: var(--white);
}

.tsf-button-forth {
  background-color: var(--forth);
  color: var(--white);
}

.tsf-button-shadow {
  transition: 0.1s linear;
  border: none;
  box-shadow: 2px 2px 3px var(--half-black);
}