.Donations {
  display: flex;
  flex-direction: column;
}

.donations-header {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 20px;
  width: var(--sixth-width);
  margin: 0 auto;
  text-align: center;
}

.donations-header:first-of-type(p) {
  margin-top: 10px;
}

.donations-header-img {
  width: 200px;
  margin: 0 auto;
}

.donations-info {
  padding: 20px;
  text-align: center;
}

.donations-info-comparison {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.donations-simulator {
  padding: 20px;
  text-align: center;
}

.donations-simulator-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--eight-width);
  margin: 0 auto;
  box-shadow: 0px 0px 3px var(--black);
  border-radius: 10px;
}

.donations-simulator-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
}

.donations-simulator-choice p {
  margin-bottom: 0;
}

.donations-simulator-buttons-container {
  text-align: center;
}

.donations-simulator-buttons {
  background-color: var(--off-white);
  border-radius: 10px;
  padding: 5px 10px;
  margin: 10px;
}

.donations-simulator-buttons:hover {
  background-color: var(--secondary);
  color: var(--off-black)
}

.donations-simulator-buttons-active {
  background-color: var(--primary);
  color: var(--off-white);
}

.donations-simulator-buttons:hover {
  box-shadow: 0px 0px 3px var(--black);
}

.donations-simulator-deduction-info {
  color: var(--third);
  font-weight: bold;
}

.donations-simulator-price {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  text-align: center;
}

.donations-simulator-element {
  display: flex;
  flex-direction: column;
}

.donations-simulator-form-text {
  position: relative;
  background-color: var(--secondary);
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0px 0px 2px var(--half-black);
}

.donations-simulator-form-text-icon {
  position: absolute;
  right: -40px;
  color: var(--primary);
  font-size: 2rem;
}

.donations-simulator-form-row-1,
.donations-simulator-form-row-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
}

.donations-simulator-input-container,
.donations-simulator-form-discount,
.donations-simulator-form-result {
  position: relative;
  background-color: var(--white);
  border: 1px solid var(--half-black);
  border-radius: 10px;
}

.donations-simulator-input {
  border: none;
  text-align: center;
}

.donations-simulator-currency {
  position: absolute;
  right: 50px;
}

.donations-simulator-choice-missing {
  padding: 0px 10px;
  border: 3px solid red;
  border-radius: 10px;
}

.donations-error-indications {
  margin-top: 10px;
  color: var(--forth);
}

.donations-call-to-actions {
  text-align: center;
  margin: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.donations-call-to-action-button {
  font-size: 2rem;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--third);
  font-weight: bold;
  color: var(--off-white);
}

.donations-icon-button {
  color: var(--forth);
}

.donations-call-to-action-button:hover {
  background-color: var(--forth);
}

.donations-call-to-action-button:hover .donations-icon-button {
  color: var(--off-white)
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .donations-header {
    width: 100%;
  }

  .donations-info-comparison {
    flex-direction: column;
  }

  .donation-card {
    margin: 10px auto;
    width: 100%;
  }

  .donations-simulator-price {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .donations-simulator-element {
    flex-direction: row;
    margin: 10px 0;
  }

  .donations-simulator-form-text {
    margin: 0 10px;
  }

  .donations-simulator-form-text-icon {
    display: none;
  }

  .donations-simulator-form-discount,
  .donations-simulator-form-result {
    margin: 0;
  }

  .donations-simulator-form-result {
    margin-bottom: 10px;
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .donations-container {
    margin: 0px !important;
    width: 100% !important;
  }

  .donations-header {
    width: 100%;
  }

  .donations-info-comparison {
    flex-direction: column;
  }

  .donation-card {
    margin: 10px auto;
    width: 100%;
  }

  .donations-simulator {
    padding: 10px 0px;
  }

  .donations-simulator-form {
    width: var(--nineth-width);
  }

  .donations-simulator-price {
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
  }

  .donations-simulator-element {
    flex-direction: row;
    margin: 10px 0;
  }

  .donations-simulator-form-text {
    margin: 0;
    margin-right: 10px;
    font-size: 0.9rem;
  }

  .donations-simulator-form-text-icon {
    display: none;
  }

  .donations-simulator-form-discount,
  .donations-simulator-form-result {
    margin: 0;
  }
}