.AdminCompleteProfile {
  padding: 30px;
}

.admin-complete-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-complete-profile-detail-row {
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid var(--quarter-black);
}

.admin-complete-profile-detail-row-note {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;
  padding: 10px;
}

.admin-complete-profile-detail-row label,
.admin-complete-profile-all-objectives-title {
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.admin-complete-profile-detail-input {
  height: 50px;
  padding: 5px 10px;
}

.admin-complete-profile-objectives-input {
  padding: 10px;
  margin: 10px 0;
}

.admin-complete-profile-detail-textarea {
  min-height: 200px;
  padding: 5px 10px;
  overflow-y: scroll;
}

.admin-complete-profile-all-objectives-container {
  /* border: 1px solid var(--quarter-black); */
  /* padding: 20px; */
  /* width: 70vw; */
  width: 100%;
}

.admin-complete-profile-objectives-input {
  border: 1px solid black;
  box-shadow: 2px 2px 5px var(--quarter-black);
}

.admin-complete-profile-objectives-other {
  width: 100%;
  min-height: 200px;
  /* padding: 5px 10px; */
  overflow-y: scroll;
}

.admin-complete-profile-active-objective {
  color: var(--white);
  background-color: var(--primary);
}

.admin-complete-profile-submit {
  padding: 10px 20px;
  color: var(--white);
  font-weight: bold;
  font-size: 1.5rem;
  background-color: var(--green);
}