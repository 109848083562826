  /*** Fonts  */
  @font-face {
    font-family: "Scripter";
    src:
      local("Scripter"),
      url("./assets/fonts/Scripter-Regular.otf") format("opentype") tech(color-COLRv1),
      url("./assets/fonts/Scripter-Regular.woff") format("woff");
  }

  * {
    box-sizing: border-box;
  }

  body,
  p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .tsf-text {
    font-family: Scripter !important;
    text-transform: uppercase;
    font-size: inherit;
  }

  /* Global link style */
  a {
    text-decoration: none;
  }

  a:visited {
    color: black;
  }