.home-partners-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.home-partners-header-logo {
  width: 20%;
}

.partners-logo-container {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: var(--eight-width);
  margin: 0 auto;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center; */
}

.partners-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  margin: 10px;
  text-align: center;
}

.partners-logo img {
  width: 150px;
  height: 150px;
}

.partners-logo figcaption {
  text-align: center;
}

.home-partners-button {
  margin-top: 20px;
  color: var(--off-white);
  background-color: var(--primary);
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 2rem;
  transition: 0.1s linear;
}

.home-partners-button:hover {
  background-color: var(--forth);
}



/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .partners-logo img {
    width: 100px;
    height: 100px;
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {

  .partners-logo img {
    width: 80px;
    height: 80px;
  }
}