.AdminLateralSidebar {
  position: absolute;
  top: 0;
  left: 0;
  /* height: var(--full-height); */
  box-shadow: 1px 0px 5px var(--half-black);
  transition: 0.1s linear;
}

.AdminLateralSidebar a {
  color: var(--off-black);
  text-decoration: none;
}

.admin-lateral-sidebar-window-button {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: var(--off-black);
}

.admin-lateral-sidebar-container {
  margin-top: 40px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
}

.admin-lateral-sidebar-container-open {
  width: 210px;
}

.admin-lateral-sidebar-link {
  width: 100%;
  margin: 20px auto;
}

.admin-lateral-sidebar-link-left {
  text-align: left;
}

.admin-lateral-sidebar-link-center {
  text-align: center;
}

.admin-lateral-sidebar-text {
  margin-left: 10px;
}

.text-visible {
  display: inline-block;
}

.text-hidden {
  display: none;
}

.icon-small {
  font-size: 1.3rem;
}

.icon-big {
  font-size: 2rem;
}

.admin-lateral-sidebar-logout {
  margin: 20px auto;
  margin-left: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--off-black);
}