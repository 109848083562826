.ContactForm {
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}

.ContactForm form {
  position: relative;
  z-index: 30;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.contact-form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-form-input-container-subject {
  width: 62vw;
  margin: 0 auto;
  margin-bottom: 10px;
}

.contact-form-input-container input,
.contact-form-row-message textarea,
.contact-form-input-container-subject input {
  font-size: 0.8rem;
  transition: 0.2s linear;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 2px var(--half-black);
}

.contact-form-input-container-subject {
  width: 62vw;
}

.contact-form-input-container-subject input {
  width: 100%;
  padding: 5px;
  margin: 10px 0;
}

.contact-form-input-container input {
  width: 30vw;
  padding: 5px;
  margin: 10px;
}

.contact-form-row-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
}

.contact-form-row-message textarea {
  height: 100px;
  padding: 10px;
}

.contact-form-input-container input:hover,
.contact-form-row-message textarea:hover,
.contact-form-input-container-subject input:hover {
  background-color: var(--beige);
}

.contact-form-row-conditions {
  display: flex;
  width: 70%;
  justify-content: flex-start;
  text-align: left;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 0.7rem;
  color: var(--medium-grey)
}

.contact-form-row-conditions input {
  margin-right: 5px;
  margin-top: 2px;
}

.contact-form-button {
  position: relative;
  overflow: hidden;
  font-size: 1.1rem;
  width: 200px;
  margin: 10px auto;
  margin-top: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--primary);
  font-weight: bold;
  color: var(--off-white);
  border: none;
}

.contact-form-button-icon {
  position: absolute;
  right: -50px;
  color: var(--off-white);
  transform: translateY(3px);
  transition: 0.2s ease-in-out;
}

.contact-form-button:hover {
  background-color: var(--third);
}

.contact-form-button:hover .contact-form-button-icon {
  right: 5px;
}

.contact-form-info {
  width: 90%;
  justify-content: flex-start;
  text-align: left;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 0.7rem;
  color: var(--medium-grey)
}

/***** Wide screen  */
@media screen and (min-width: 1200px) {

  .contact-form-row-message textarea {
    width: 62vw;
  }

  .contact-form-input-container-subject {
    width: 62vw;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1200px) {

  .contact-form-row-message textarea {
    width: 62vw;
  }

  .contact-form-input-container-subject {
    width: 62vw;
  }
}

/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {

  .contact-form-row {
    flex-direction: column;
  }

  .contact-form-input-container {
    width: 100%;
    margin: 0 auto;
  }

  .contact-form-input-container-subject {
    width: 100%;
    margin: 0 auto;
  }

  .contact-form-input-container input,
  .contact-form-input-container-subject input {
    width: 90%;
    padding: 5px;
    margin: 10px 0;
  }

  .contact-form-row-message {
    margin: 10px 0px;
  }

  .contact-form-row-message textarea {
    width: 90%;
  }

}


/***** Mobile screen  */
@media screen and (max-width: 480px) {

  .contact-form-row {
    flex-direction: column;
  }

  .contact-form-input-container {
    width: 100%;
  }

  .contact-form-input-container-subject {
    width: 100%;
  }

  .contact-form-input-container input,
  .contact-form-input-container-subject input {
    width: 90%;
    padding: 5px;
    margin: 10px 0;
  }

  .contact-form-row-message {
    margin: 10px 0px;
  }

  .contact-form-row-message textarea {
    width: 90%;
  }

}