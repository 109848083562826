.AdminHome {
  display: flex;
  flex: 1;
  min-height: var(--full-height);
}

.admin-home-container {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-top: 30px;
}

.menuOpen {
  margin-left: 210px;
}

.menuClose {
  margin-left: 70px;
}