.partner-bonus-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 20vw;
  margin: 0 auto;
}

.partner-bonus-container>img {
  max-width: 100px;
  margin-bottom: 10px;
}

.partner-bonus-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.partner-bonus-info>p {
  text-align: center;
}

/***** Wide screen  */
@media screen and (min-width: 1024px) {}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .partner-bonus-info>p {
    font-size: 0.9rem;
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .partner-bonus-container {
    width: 35vw;
  }

  .partner-bonus-info>p {
    font-size: 0.8rem;
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .partner-bonus-container {
    width: 35vw;
  }

  .partner-bonus-info>p {
    font-size: 0.8rem;
  }
}