.cv-container {
  margin-bottom: 20px;
}

.cv-container h1 {
  max-width: 70vw;
  margin: 0 auto;
  margin-bottom: 20px;
}

.cv-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.3rem;
}

.cv-header-image {
  width: 150px;
  height: 150px;
  border-radius: 200px;
  overflow: hidden;
  margin-bottom: 20px;
}

.cv-header-image img {
  width: 100%;
}

.cv-header-title {
  font-size: 1.3rem;
  color: var(--forth);
}

.cv-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}

.cv-cards-title {
  text-align: left;
  margin: 20px 0px
}

.cv-header-linkedin {
  font-size: 1rem;
  color: var(--primary);
}

.cv-header-container .cv-header-link {
  font-size: 1rem;
  color: var(--primary);
}


/***** Wide screen  */
@media screen and (min-width: 1200px) {
  .cv-article-container {
    width: var(--eight-width);
  }
}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .cv-article-container {
    width: var(--eight-width);
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .cv-header-image {
    width: 130px;
    height: 130px;
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .cv-header-image {
    width: 100px;
    height: 100px;
  }
}