.become-partner-container {
  overflow: hidden;
}

.become-partner-header {
  position: relative;
  width: var(--eight-width);
  margin: 0 auto;
  text-align: center;
}

.become-partner-header>img {
  width: 20%;
  margin-bottom: 20px;
}

.become-partner-content-container {
  position: relative;
  z-index: 50;
  padding-top: 20px;
  padding-bottom: 20px;
}

.become-partner-header-decoration-1 {
  position: absolute;
  background-color: var(--forth);
  opacity: 0.2;
  z-index: -1;
  top: 200px;
  right: 100px;
  height: 250px;
  width: 250px;
  border-radius: 58% 42% 54% 46% / 64% 28% 72% 36%;
}

.become-partner-content-header {
  text-align: center;
}

.become-partner-content-header>p {
  margin-bottom: 0px;
}

.become-partner-content-header>h2 {
  margin-bottom: 30px;
}

.become-partner-content-values-list {
  display: flex;
  justify-content: space-evenly;
  width: 80vw;
  margin: 0 auto;
  margin-bottom: 30px;
}

.become-partner-offers-list {
  position: relative;
  display: flex;
  flex-direction: row;
  width: var(--eight-width);
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
}

.become-partner-bottom-image {
  width: var(--full-width);
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.become-partner-bottom-image>img {
  width: 300px;
}


/***** Wide screen  */
@media screen and (min-width: 1024px) {}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1024px) {}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .become-partner-content-values-list {
    flex-wrap: wrap;
  }

  .become-partner-offers-list {
    flex-direction: column;
  }

  .become-partner-bottom-image {
    width: auto;
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .become-partner-content-values-list {
    flex-wrap: wrap;
  }

  .become-partner-offers-list {
    flex-direction: column;
  }

  .become-partner-bottom-image {
    width: auto;
  }

  .become-partner-bottom-image>img {
    width: 200px;
  }
}