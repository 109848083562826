.HomeJoinUs {
  padding: 0 20px;
  margin: 0 auto;
}

.home-join-us-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home-join-us-cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.home-join-us-image {
  width: 150px;
  margin: 0 auto;
}



/***** Wide screen  */
@media screen and (min-width: 1024px) {
  .HomeJoinUs {
    min-height: auto !important;
    height: auto !important;
  }

  .home-join-us-container {
    min-height: auto !important;
    height: auto !important;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .HomeJoinUs {
    min-height: auto !important;
    height: auto !important;
  }

  .home-join-us-container {
    min-height: auto !important;
    height: auto !important;
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .HomeJoinUs {
    min-height: auto !important;
    height: auto !important;
  }

  .home-join-us-container {
    min-height: auto !important;
    height: auto !important;
  }

  .home-join-us-cards-container {
    flex-direction: column;
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .HomeJoinUs {
    min-height: auto !important;
    height: auto !important;
  }

  .home-join-us-container {
    min-height: auto !important;
    height: auto !important;
  }

  .home-join-us-cards-container {
    flex-direction: column;
  }
}