.home-news-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}

.HomeNews .home-news-container {
  position: relative;
}

.home-news-button-container {
  text-align: center;
}

.HomeNews .home-news-container .home-news-button {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--off-white);
  background-color: var(--primary);
  padding: 10px 30px;
  border-radius: 20px;
  transition: 0.1s linear;
}

.home-news-button:hover {
  background-color: var(--forth);
}