.formations-content {
  box-shadow: 0px 0px 2px var(--quarter-black);
  border-radius: 10px;
  margin: 10px auto;
  padding: 10px;
  width: var(--sixth-width);
}

.formations-content-description {
  width: 80%;
  margin: 20px auto;
}

.formation-content-arrow {
  cursor: pointer;
}

.formation-content-arrow:hover {
  color: var(--primary);
}

/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .formations-content {
    width: var(--eight-width);
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .formations-content {
    width: var(--eight-width);
  }
}