/******
  Become Partner Card
*/

.become-partner-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  width: 35vw;
  padding: 20px;
  color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 3px var(--off-black);
}

.become-partner-card-container ul {
  text-align: left;
  padding-left: 0px;
}

.become-partner-card-container li {
  list-style-type: none;
  font-size: 0.8rem;
  margin: 5px 0;
}

.become-partner-card-container a {
  margin: 0 auto;
}

.become-partner-card-primary {
  background-color: var(--primary);
}

.become-partner-card-forth {
  background-color: var(--forth);
}

.become-partner-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.become-partner-card-header-title {
  font-size: 2.5rem !important;
  text-align: left;
  margin-bottom: 20px;
  line-height: normal;
}

.become-partner-card-header-subtitle {
  font-size: 0.8rem;
}

.become-partner-card-header-info-container {
  width: 100px;
}

.become-partner-card-header-price {
  text-align: right;
  font-weight: bold;
  color: var(--off-white);
}

.become-partner-card-header-info {
  margin-top: -20px;
  text-align: right;
  font-size: 0.7rem;
  font-style: italic;
  color: var(--extra-light-grey);
}

.become-partner-card-content-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.become-partner-card-list-type {
  font-size: 0.9rem;
  color: var(--third);
}

.become-partner-card-button {
  width: 200px;
  background-color: var(--half-black);
  font-size: 1.3rem;
  color: var(--white);
  border-radius: 10px;
  border: none;
  padding: 10px 0px;
  margin: 0 auto;
}

.become-partner-card-button:hover {
  background-color: var(--third);
}


/******
  Donation Card
*/

.donation-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: var(--off-white);
  margin: 0px 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px black;
  color: var(--off-white)
}

.donation-card-primary {
  background-color: var(--primary);
}

.donation-card-forth {
  background-color: var(--forth);
}

.donation-card-img {
  width: 30%;
}


/******
  Become Home Card (Subscriber / Subscriber-Benevolent)
*/

.become-home-card {
  display: flex;
  cursor: pointer;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0px 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px black;
  width: 50%;
  max-width: 300px !important;
  height: 30vh;
  font-size: 2rem !important;
}


/******
  Admin Requests Card
*/

.admin-requests-card-container {
  width: 90%;
  margin: 10px auto;
  padding: 10px;
  padding-bottom: 20px;
  border: 1px solid black;
  color: black;
}

.admin-requests-card-done {
  background-color: var(--light-green);
}

.admin-requests-card-ongoing {
  background-color: var(--light-orange);
}

.admin-requests-card-row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 10px;
}

.admin-requests-card-row-element {
  padding: 10px;
  margin: 0 10px;
}

.admin-requests-card-row-message {
  justify-content: flex-start;
}

.admin-requests-card-handle-button-container {
  text-align: center;
}

.admin-requests-card-handle-button {
  padding: 10px 20px;
  margin: 10px;
  border-radius: 20px;
  border: none;
  background-color: var(--primary);
  color: var(--white) !important;
  font-size: 1.3rem !important;
}

.admin-request-card-button {
  text-align: center;
}


/******
  CV Card
*/

.cv-article-container {
  margin: 10px auto;
  padding: 10px 20px;
  border-bottom: 1px solid var(--ten-black);
  width: var(--half-width);
  text-align: justify;
}

.cv-article-date {
  font-style: italic;
}

.cv-article-type {
  font-weight: bold;
}


/******
  News Card
*/

.news-card-container {
  position: relative;
  background-color: var(--white);
  box-shadow: 1px 1px 3px black;
  margin: 10px;
}

.news-card-delete-button {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid var(--extra-light-grey);
  background-color: var(--white);
  transition: 0.1s linear;
}

.news-card-delete-button:hover {
  background-color: var(--forth);
  color: var(--white);
  border: 1px solid var(--white);
}


/******
  News TSF Card
*/

.news-tsf-card-container {
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  width: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  margin: 10px;
  background-color: var(--white);
  box-shadow: 1px 1px 3px var(--super-light-grey);
}

.news-tsf-card-left-container {
  display: flex;
  width: 50%;
}

.news-tsf-card-image {
  display: flex;
  width: 60%;
  margin: 0 auto;
  object-fit: contain;
  justify-content: center;
}

.news-tsf-card-right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 2px 10px;
}

.news-page-tsf-card-container {
  position: relative;
  border: 2px solid var(--half-black);
  border-radius: 5px;
  width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  margin: 10px;
  background-color: var(--white);
}

.news-tsf-card-delete-button {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid var(--tsf-grey);
  background-color: var(--white);
  transition: 0.1s linear;
}

.news-tsf-card-delete-button:hover {
  background-color: var(--forth);
  color: var(--white);
  border: 1px solid var(--white);
}

.news-tsf-card-header {
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.news-tsf-card-title {
  font-family: Scripter;
  font-size: 1.2rem;
}

.news-tsf-card-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
  font-size: small;
}

.news-tsf-card-content {
  text-align: left;
  font-size: small;
}

.news-tsf-card-link {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: small;
}

.news-tsf-card-link-title {
  color: var(--primary);
}

.news-tsf-card-date-icon,
.news-tsf-card-link-icon {
  margin-right: 5px;
}

.news-tsf-card-link-icon {
  color: var(--primary);
}

.news-tsf-card-image {
  display: flex;
  width: 70%;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 10px;
  object-fit: cover;
  justify-content: center;
}

.news-content-p {
  margin-bottom: 5px;
  line-height: normal;
}

/* Mobile */
.news-tsf-card-container-mobile {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--half-black);
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  margin: 10px;
}

.news-tsf-card-image-container-mobile {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.news-tsf-card-image-mobile {
  width: 80%;
}

.news-tsf-card-text-container-mobile {
  padding: 0px 10px;
}

.news-tsf-card-content-mobile {
  text-align: justify;
}


/******
  SupportUs Card
*/
.support-us-card {
  border: 1px solid var(--extra-light-grey);
  box-shadow: 1px 1px 3px var(--super-light-grey);
  background-color: var(--white);
  margin: 10px;
  padding: 10px;
  flex: 1 0 30%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: 0.1s linear;
  border-radius: 10px;
}

.support-us-card:hover {
  box-shadow: 0px 0px 5px 3px var(--super-light-grey);
}

.support-us-card:first-child {
  flex: 0 0 60%;
}

.support-us-card-title {
  font-size: 1.2rem;
  margin: 0;
}

.support-us-card-image-container {
  height: 30%;
  text-align: center;
}

.support-us-card-image-container>img {
  height: 100%;
}

.support-us-card-donations-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  text-align: center;
  color: var(--white);
}

.support-us-card-donations-info-container> :first-of-type {
  background-color: var(--primary);
}

.support-us-card-donations-info-container> :last-of-type {
  background-color: var(--forth);
}

.support-us-card-donations-info {
  padding: 5px 10px;
  border-radius: 20px;
}

.support-us-card-donations-subtitle {
  font-size: 0.9rem;
}

.support-us-card-donations-subtitle-2 {
  font-size: 1.1rem;
}

.support-us-card-donations-text,
.support-us-card-text {
  font-size: 0.7rem;
  margin: 0;
}

.support-us-card .support-us-card-button {
  border: 1px solid var(--primary);
  color: var(--white);
  border-radius: 20px;
  padding: 5px 10px;
  margin: 0 auto;
  background-color: var(--primary);
  text-transform: uppercase;
  font-size: 1.1rem;
  transition: 0.1s linear;
  font-family: Scripter;
  text-align: center;
}

.support-us-card-button:hover {
  background-color: var(--forth);
}


/******
  Member Card
*/

.members-card-container {
  display: flex;
  flex-direction: column;
  width: var(--sixth-width);
  margin: 10px auto;
}

.members-card-top {
  display: flex;
  flex-direction: row;
}

.members-card-image {
  width: 80px;
  height: 80px;
}

.members-card-image img {
  width: 70px;
  height: 70px;
  border-radius: 40px;
  box-shadow: 2px 2px 2px var(--quarter-black);
}

.members-card-top-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.members-card-top-name {
  font-size: 1.5rem;
  color: var(--off-black)
}

.members-card-top-occupation {
  font-size: 0.9rem;
}

.members-card-divider {
  width: 60%;
  height: 1px;
  margin: 10px auto;
  background-color: var(--extra-light-grey);
}

.members-card-description {
  font-size: 0.9rem;
  text-align: left;
}

.members-card-description .members-card-more-button {
  background-color: var(--light-grey);
  color: var(--white);
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 10px 0;
}

.members-card-container .members-card-cv-link {
  color: var(--primary);
  cursor: pointer;
}

.members-card-cv-icon {
  margin-right: 5px;
}

.members-card-container .members-card-cv-link:hover {
  color: var(--third);
}



/******
  Benevolent Member Card
*/

.benevolent-members-card-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: 200px;
  height: 350px;
  margin: 5px;
  padding: 5px;
  border: 1px solid var(--extra-light-grey);
  border-radius: 10px;
  box-shadow: 1px 1px 3px var(--extra-light-grey);
}

.benevolent-members-card-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benevolent-members-card-image {
  margin-bottom: 10px;
}

.benevolent-members-card-image img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px var(--quarter-black);
}

.benevolent-members-card-top-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.benevolent-members-card-top-name {
  font-size: 1.5rem;
  color: var(--off-black);
  text-align: center;
}

.benevolent-members-card-top-occupation {
  font-size: 0.9rem;
}

.benevolent-members-card-divider {
  width: 90%;
  height: 1px;
  margin: 10px auto;
  background-color: var(--extra-light-grey);
}

.benevolent-members-card-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
}

.benevolent-members-card-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: var(--third);
  color: var(--white);
}



/******
  RESPONSIVE
*/


/***** Wide screen  */
@media screen and (min-width: 1200px) {

  /******
  Become Home Card (Subscriber / Subscriber-Benevolent)
*/
  .become-home-card {
    max-height: 200px;
  }

  /******
  SupportUs Card
*/
  .support-us-container {
    margin-bottom: 20px;
  }

  .support-us-cards-container {
    width: 90%;
  }

  .support-us-card {
    padding: 10px;
  }

  .support-us-card-donations-info {
    margin: 0px 20px;
    padding: 10px;
    width: 80%;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1200px) {

  /******
  Become Home Card (Subscriber / Subscriber-Benevolent)
*/
  .become-home-card {
    margin: 20px auto;
    padding: 10px;
    width: 80%;
    height: 200px;
  }

  /******
  Become Partner Card
*/

  .become-partner-card-container {
    min-height: 550px;
    height: auto;
  }

  /******
  SupportUs Card
*/
  .support-us-card-donations-info {
    margin: 0px 5px;
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {

  /******
  Become Home Card (Subscriber / Subscriber-Benevolent)
*/
  .become-home-card {
    margin: 20px auto;
    padding: 10px;
    width: 80%;
    height: 200px;
  }

  /******
  Become Partner Card
*/

  .become-partner-card-container {
    height: auto;
    width: 100%;
    margin: 20px auto;
  }

  .become-partner-card-header-title {
    margin-top: 20px;
  }

  .become-partner-card-container li {
    font-size: 0.9rem;
  }

  .become-partner-card-header-subtitle {
    font-size: 1.1rem;
  }

  .become-partner-card-list-type {
    font-size: 1rem;
  }

  /******
  CV Card
*/
  .cv-article-container {
    width: var(--eight-width);
  }


  /******
  SupportUs Card
*/
  .support-us-container {
    margin: 0px 10px 10px 10px;
    padding: 10px 5px;
  }

  .support-us-cards-container {
    width: 100%;
  }

  .support-us-card:first-child {
    flex: 0 100%;
  }

  .support-us-card-title {
    font-size: 1.1rem;
  }

  .support-us-card-image-container {
    height: 20%;
  }

  .support-us-card-donations-info {
    margin: 0px 5px;
  }

  .support-us-card-donations-subtitle {
    font-size: 0.8rem;
  }

  .support-us-card-donations-subtitle-2 {
    font-size: 1rem;
  }

  .support-us-card-donations-text,
  .support-us-card-text {
    font-size: 0.6rem;
  }

  .support-us-card-button {
    border-radius: 10px;
    font-size: 0.8rem;
  }

  /******
  Member Card
*/
  .members-card-container {
    width: var(--eight-width);
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {

  /******
  Become Home Card (Subscriber / Subscriber-Benevolent)
*/
  .become-home-card {
    margin: 20px auto;
    padding: 10px;
    width: 70vw;
    height: 100px;
  }

  /******
  Become Partner Card
*/

  .become-partner-card-container {
    height: auto;
    width: 100%;
    margin: 20px auto;
  }

  .become-partner-card-container li {
    font-size: 0.9rem;
  }

  .become-partner-card-list-type {
    font-size: 1rem;
  }

  /******
  CV Card
*/
  .cv-article-container {
    width: var(--eight-width);
  }

  /******
  SupportUs Card
*/
  .support-us-container {
    margin: 0px 10px 10px 10px;
    padding: 10px 5px;
  }

  .support-us-cards-container {
    width: 100%;
  }

  .support-us-card:first-child {
    flex: 0 100%;
  }

  .support-us-card-title {
    font-size: 1.1rem;
  }

  .support-us-card-image-container {
    height: 20%;
  }

  .support-us-card-donations-info {
    margin: 0px 5px;
  }

  .support-us-card-donations-subtitle {
    font-size: 0.8rem;
  }

  .support-us-card-donations-subtitle-2 {
    font-size: 1rem;
  }

  .support-us-card-donations-text,
  .support-us-card-text {
    font-size: 0.6rem;
  }

  .support-us-card-button {
    border-radius: 10px;
    font-size: 0.8rem;
  }

  /******
  Member Card
*/
  .members-card-container {
    width: var(--eight-width);
  }
}