.support-us-container {
  background-color: var(--white);
  margin: 5px 20px;
  padding: 20px 10px;
}

.support-us-cards-container {
  display: flex;
  flex-wrap: wrap;
  width: var(--eight-width);
  margin: 10px auto;
}