.Home {
  margin-top: var(--navbar-desktop-height) !important;
}

.home-carousel {
  width: 100vw;
}

.home-carousel-decoration {
  position: relative;
  z-index: -10;
  margin: 0 auto;
  width: 100vh;
  height: 0px;
  border-style: solid;
  border-width: 150px 200px 0 200px;
  border-color: var(--primary) transparent transparent transparent;
  transform: rotate(0deg);
  margin-top: -10px;
}

.carousel-image-container {
  position: relative;
  width: 100%;
}

.carousel-image-main {
  position: relative;
  width: 50%;
}

.carousel-image-odd {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0px;
  margin-top: 20px;
  position: absolute;
  height: 100%;
  max-height: 75vh;
  object-fit: contain;
}

.carousel-image-legend {
  position: absolute;
  text-shadow: 2px 2px 10px var(--half-black);
  color: var(--white);
  -webkit-text-stroke: 1px var(--quarter-black);
  font-size: 2rem !important;
  top: 40%;
  left: 40vw;
  width: 55vw;
  transform: translateY(-50%);
}

.carousel-legend-author {
  color: var(--white);
}

.carousel-legend-bold {
  color: var(--third);
}

.carousel-image-legend-1 {
  color: var(--primary);
}

.carousel-image-legend-2 {
  color: var(--third);
}

.carousel-mobile-image {
  display: none;
}

.carousel-desktop-image {
  display: block;
}


/***** Wide screen  */
@media screen and (min-width: 1000px) {

  .carousel-image-legend {
    top: 40%;
  }

  .carousel-legend-author {
    font-size: 1.3rem;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .carousel-image-legend {
    font-size: 1.7rem !important;
  }

  .carousel-legend-author {
    font-size: 1.2rem;
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {

  .Home {
    margin-top: 0px !important;
  }

  .carousel-desktop-image {
    display: none;
  }

  .carousel-mobile-image {
    display: block;
  }

  .carousel-image-legend {
    font-size: 1rem !important;
  }

  .carousel-legend-author {
    font-size: 0.7rem;
  }

}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .Home {
    margin-top: 0px !important;
  }

  .carousel-image-legend {
    top: 50%;
    left: 30vw;
    width: 60vw;
    /* text-align: justify; */
  }

  .carousel-image-legend {
    font-size: 0.9rem !important;
    color: var(--white);
    text-shadow: 1px 1px 3px var(--black);
  }

  .carousel-legend-author {
    font-size: 0.7rem;
    color: var(--white);
  }

  .carousel-desktop-image {
    display: none;
  }

  .carousel-mobile-image {
    display: block;
  }

  .carousel-image-odd {
    height: 90%;
  }
}