.AdminConnection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--white);
  position: relative;
}

.admin-connection-return-btn {
  position: absolute;
  display: flex;
  left: 10px;
  top: 10px;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: lowercase;
  transition: 0.1s linear;
  font-size: 0.8rem;
}

.admin-connection-return-btn:hover {
  opacity: 1;
}

.admin-connection-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.admin-connection-form-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--black);
}

.admin-connection-form-button {
  width: 50%;
  background-color: var(--primary);
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: 0.1s linear;
}

.admin-connection-form-button:hover {
  background-color: var(--green);
}