.join-us-form-global {
  width: 100%;
}

.join-us-component-title {
  margin-bottom: 20px;
}

.join-us-divider {
  width: 80%;
  height: 2px;
  margin: 20px auto;
  background-color: var(--ten-black);
}

.join-us-actions-title,
.join-us-commitments-title {
  font-weight: bold;
  text-align: center;
}

.join-us-actions-sub-title {
  font-style: italic;
}

.join-us-buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.join-us-button {
  background-color: var(--off-white);
  margin: 10px;
  border-radius: 20px;
  border: 1px solid var(--off-grey);
  color: var(--black);
  width: 200px;
  height: 80px;
}

.join-us-regular-button {
  background-color: var(--off-white);
  margin: 10px;
  border-radius: 20px;
  border: 1px solid var(--off-grey);
  color: var(--black);
  width: 80px;
  height: 40px;
}

.join-us-button-active {
  background-color: var(--primary);
  box-shadow: none !important;
  color: var(--white);
  transform: translateY(3px);
}

.join-us-regular-button-active {
  background-color: var(--primary);
  box-shadow: none !important;
  color: var(--white);
}

.join-us-textarea-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 15px auto;
}

.join-us-textarea-container>label {
  margin: 10px auto;
}

.join-us-textarea-container>textarea {
  width: 80%;
  min-height: 150px;
}

.join-us-dates-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 70%;
}

.join-us-date-container,
.join-us-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.join-us-date-container>input,
.join-us-text-container>input {
  margin-left: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--ten-black);
}

.join-us-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.join-us-text-container>input {
  margin: 10px 0;
  width: 60%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--ten-black);
}

.join-us-buttons-left-container {
  justify-content: flex-start;
}