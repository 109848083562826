.programs-content {
  box-shadow: 0px 0px 2px var(--quarter-black);
  border-radius: 10px;
  margin: 10px auto;
  padding: 10px;
  width: var(--sixth-width);
}

.programs-content-description {
  width: 80%;
  margin: 20px auto;
}

.programs-content-arrow {
  cursor: pointer;
}

.programs-content-arrow:hover {
  color: var(--primary);
}


/***** Desktop and Wide screen  */
@media screen and (min-width: 768px) {
  .Programs {
    min-height: var(--half-height) !important;
  }

  .programs-container {
    min-height: var(--half-height);
  }
}

/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .programs-content {
    width: var(--eight-width);
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .programs-content {
    width: var(--eight-width);
  }
}