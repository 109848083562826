/****** DESKTOP MENU  */
.desktop-menu {
  display: flex;
  position: fixed;
  z-index: 300;
  width: var(--full-width);
  top: 30px;
  transition: 0.1s linear;
  height: var(--navbar-desktop-height);
  color: var(--off-black);
  box-shadow: 0px 0px 3px var(--quarter-black);
  text-transform: uppercase;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.desktop-menu a {
  text-decoration: none;
  color: var(--off-black)
}

.emergency-container {
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  top: -30px;
  height: 30px;
  width: var(--full-width);
  color: var(--off-white);
  background-color: var(--primary);
  padding-right: 20px;
}

.emergency-icon {
  margin-right: 10px;
}

.emergency-numbers-container {
  display: flex;
  flex-direction: column;
}

.emergency-numbers-container p {
  margin: 0;
  font-size: 0.5rem;
}

.emergencyNumber {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  font-weight: bold;
  color: var(--forth);
  height: 20px;
}

.desktop-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  color: #fff;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  height: var(--navbar-desktop-height);
}

.desktop-menu-logo-container {
  position: relative;
  z-index: 500;
  width: 8vw;
  max-width: 80px;
}

.desktop-menu-logo {
  width: 100%;
}

.desktop-menu-items {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: var(--off-black);
}

.desktop-menu-item {
  position: relative;
  cursor: pointer;
  /* font-size: 0.9rem; */
  font-size: 1.2vw;
}

.desktop-menu-item:hover {
  color: var(--primary);
}

.desktop-menu-item:hover .desktop-menu-item-title {
  color: var(--primary);
}

.active {
  color: var(--primary);
}

.desktop-sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  background-color: #222;
  padding: 10px;
  border-radius: 0 0 5px 5px;
}

.desktop-sub-menu-divider {
  height: 2px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  background-color: var(--extra-light-grey);
}

.desktop-menu-item:hover .desktop-sub-menu {
  display: flex;
  min-width: 20vw;
  background-color: var(--white);
}

.desktop-sub-menu-item {
  position: relative;
  color: var(--off-black);
  padding-right: 10px;
  margin-right: -10px;
}

.desktop-sub-menu-item:hover {
  color: var(--primary)
}

.desktop-menu-item-title {
  margin-bottom: 0px;
}

.desktop-sub-menu-item-title {
  margin-bottom: 0px;
  color: var(--off-black);
}

.desktop-sub-menu-item-title:hover {
  color: var(--primary)
}

.desktop-second-sub-menu {
  display: none;
}

.desktop-sub-menu-item:hover .desktop-second-sub-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: calc(20vw - 10px);
  top: 0px;
  border-radius: 0 0 5px 5px;
  min-width: 20vw;
  background-color: var(--white);
  padding: 10px 0;
}

.desktop-second-sub-menu-item {
  color: var(--off-black);
  padding-left: 15px;
}

.desktop-second-sub-menu-item:hover {
  color: var(--primary)
}

.desktop-menu-actions-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.desktop-menu-button-img {
  height: 35px;
  padding-left: 5px;
  padding-right: 10px;
  border: none;
  background-color: transparent;
}

.desktop-menu-button-img>img {
  height: 100%;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--white);
  transition: 0.1s linear;
  border: 1px solid var(--extra-light-grey);
  box-shadow: 2px 2px 3px var(--half-black);
}

.desktop-menu-button-img:hover>img {
  transform: translateY(3px);
  background-color: var(--forth);
  box-shadow: none;
}

.desktop-menu-button-donations {
  height: 35px;
  border-radius: 20px;
  background-color: var(--third);
  color: var(--white);
  font-size: 1rem;
  margin-left: 0px;
  margin-right: 10px;
  padding: 3px 10px 0px 13px;
}

.desktop-menu-donations-icon-button {
  color: var(--forth)
}

.desktop-menu-buttons:hover {
  background-color: var(--forth);
}

.desktop-menu-buttons:hover .desktop-menu-donations-icon-button {
  color: var(--off-white)
}



/****** MOBILE MENU  */
.mobile-menu {
  display: none;
  position: fixed;
  z-index: 3000;
  height: 50px;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: var(--white);
  border-bottom: 1px solid var(--extra-light-grey);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mobile-menu-closed {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.mobile-menu-icon-container {
  height: 50px;
  padding: 10px 20px;
  width: 50px;
  position: relative;
  top: 0px;
  left: 0px;
}

.mobile-menu-icon {
  color: var(--off-black);
}

.mobile-menu-logo-container {
  width: 100%;
  text-align: center;
  flex: 1;
}

.mobile-menu-logo {
  width: 100px;
}

.mobile-menu-layout {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: var(--eight-black);
  width: 100%;
  min-height: 110vh;
  height: 150vh;
  transition: 0.2s linear;
}

.mobile-menu-container {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: var(--full-width);
  min-height: 150vh;
  height: auto;
  background-color: var(--white);
  padding-top: 40px;
}

.mobile-menu-close-button {
  position: absolute;
  right: 10px;
  top: 0px;
  color: var(--black);
  font-size: 2.5rem;
}

.mobile-menu-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1rem;
  padding-left: 30px;
}

.mobile-sub-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 100%;
  min-width: 200px;
  margin: 0px auto;
  text-align: left;
  padding-left: 20px;
}

.mobile-sub-menu-item {
  color: var(--off-black);
  text-align: left;
  width: 70%;
  margin: 10px 0;
}

.mobile-second-sub-menu {
  height: auto;
  width: 100%;
  min-width: 200px;
  margin: 0px auto;
  text-align: left;
  padding-left: 20px;
}

.mobile-second-sub-menu-item {
  display: flex;
  width: 100%;
  color: var(--off-black);
  margin: 10px auto;
}

.mobile-menu-divider {
  height: 1px;
  width: 80%;
  min-width: 150px;
  margin: 0px auto;
  margin-bottom: 15px;
  text-align: left;
  background-color: var(--extra-light-grey);
}

.mobile-sub-menu-divider {
  height: 1px;
  width: 80%;
  min-width: 150px;
  margin: 0px auto;
  text-align: left;
  margin-left: 0px;
  background-color: var(--extra-light-grey);
}

.mobile-menu-actions-buttons {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
}

.mobile-menu-actions-button-img-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px black;
  height: 40px;
  padding: 2px 5px;
  background-color: var(--white);
}

.mobile-menu-actions-button-img-logo-text {
  margin-left: 10px;
}

.mobile-menu-actions-button-img-logo img {
  height: 90%;
}

.mobile-menu-actions-button-img {
  width: 130px;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 2px 2px 3px var(--half-black);
  margin: 5px 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.mobile-menu-actions-button-img img {
  width: 90%;
}

.mobile-menu-buttons,
.mobile-menu-social-element {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
  width: 150px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  font-size: 1rem;
  margin: 5px 0;
  padding: 12px 3px 8px 7px;
}

.mobile-menu-button-donation {
  background-color: var(--third);
  color: var(--white);
}

.mobile-menu-button-benevolent {
  background-color: var(--primary);
  color: var(--white);
}

.mobile-menu-donations-icon-button {
  color: var(--forth)
}

.mobile-menu-socials-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 10px;
}

.mobile-menu-social-element-mail {
  background-color: var(--forth);
  color: var(--white);
}

.mobile-menu-social-element-facebook,
.mobile-menu-social-element-instagram,
.mobile-menu-social-element-linkedin {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  /* text-align: center; */
  font-size: 2rem;
  margin: 10px;
  padding: 0px;
  background-color: var(--white);
  color: var(--black);
}

.mobile-menu-social-element-icon {
  font-size: x-large;
}


/* @media screen and (max-width: 768px) { */
@media screen and (max-width: 900px) {

  .desktop-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
}


/* 
@media screen and (min-width: 992px) and (max-width: 1080px) {
  .menu-item {
    font-size: 0.8rem;
  }
} */

/**** Laptop screen */
/* @media screen and (min-width: 768px) and (max-width: 992px) {
} */


/**** Laptop screen font-sizes */
/* @media screen and (min-width: 768px) and (max-width: 870px) {
  .menu-item {
    font-size: 0.8rem;
  }

  .navbar-button-donation {
    font-size: 0.7rem;
  }
} */


/* *** Tablet screen
@media screen and (max-width: 768px) {
} */