.AppHome {
  position: relative;
  z-index: 10;
  width: var(--full-width);
  height: var(--full-height);
  margin: 0 auto;
  overflow: hidden;
  background-color: var(--white)
}

.AppHome,
.AppForm {
  background: url('../../assets/images/TSF/tsf-bg.png');
}

.AppForm a,
.AppHome a {
  color: var(--off-black);
  text-decoration: none;
}

.app-header {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10px;
  border-bottom: 2px solid var(--ten-black);
  background-color: var(--white);
}

.app-header-return-btn {
  position: absolute;
  display: flex;
  left: 10px;
  top: 10px;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: lowercase;
  transition: 0.1s linear;
  font-size: 0.8rem;
}

.app-header-return-btn:hover {
  opacity: 1;
}

.app-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 80vh;
}

.app-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 250px;
  height: 250px;
  border: 1px solid var(--quarter-black) !important;
  border-radius: 20px;
  background-color: var(--white);
}

.app-button-container h2 {
  margin-bottom: -20px;
}

.app-button {
  width: 100%;
}

.app-form-top-page-indicator {
  position: absolute;
  right: 20px;
  top: 10px;
  color: var(--light-grey)
}

.app-form-header {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--ten-black);
  background-color: var(--white);
}

.app-form-header-return-btn {
  position: absolute;
  display: flex;
  left: 10px;
  top: 10px;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: lowercase;
  transition: 0.1s linear;
  font-size: 0.8rem;
}

.app-form-header-return-btn:hover {
  opacity: 1;
}

.app-form-global {
  position: relative;
  z-index: 10;
  width: var(--eight-width);
  margin: 0 auto;
  overflow: hidden;
}

.app-form-container {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
}

.app-form-presentation-info {
  font-size: 0.5rem;
}

.app-profile-input-row {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.app-profile-input-row-radio {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.app-profile-input-column {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.app-profile-input-group {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 10px;
}

.app-profile-radio-group-full {
  padding: 10px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 2px var(--quarter-black);
}

.app-profile-input-group-full {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-profile-input-group-full input {
  padding: 10px;
  height: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 2px var(--quarter-black);
}

.app-profile-input-group input,
.app-profile-input-group select {
  padding: 10px;
  height: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 2px var(--quarter-black);
  color: var(--black);
}

.app-form-select {
  width: var(--half-width) !important;
}

.app-profile-radio-group-element {
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  padding: 10px !important;
  margin: 0 10px;
  width: 150px;
  transition: 0.1s linear;
}

.app-profile-radio-group-element-active {
  background-color: var(--third);
  color: var(--white);
}

.app-profile-radio-group-full input[type="radio"] {
  display: none;
}

.app-input-wrong {
  background-color: var(--input-wrong-color);
}

.app-input-wrong.form-input::placeholder {
  color: var(--off-white);
  opacity: 1;
}

.app-input-init.form-input::placeholder {
  color: var(--light-grey);
  opacity: 1;
}

.app-form-thankyou {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.app-form-thankyou img {
  width: 200px;
}

.app-form-thankyou-btn {
  position: relative;
  background-color: var(--primary);
  padding: 10px 0px;
  width: 200px;
  border-radius: 10px;
  color: var(--white);
  margin: 20px auto;
}

.app-form-thankyou-btn:hover {
  background-color: var(--third);
}

.buttons-container {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-evenly;
  width: var(--half-width);
  margin: 10px auto;
}

.progressbar-bg {
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  height: 10px;
  width: 60vw;
  background-color: silver;
  border-radius: 10px;
}

.progressbar {
  position: relative;
  height: 10px;
  border-radius: 10px;
  background-color: var(--primary);
  transition: 0.5s linear;
}

.progressbar-pro-1 {
  width: 20%;
}

.progressbar-pro-2 {
  width: 40%;
}

.progressbar-pro-3 {
  width: 60%;
}

.progressbar-pro-4 {
  width: 80%;
}

.progressbar-pro-5 {
  width: 100%;
}

.progressbar-benevolent-1 {
  width: 25%;
}

.progressbar-benevolent-2 {
  width: 50%;
}

.progressbar-benevolent-3 {
  width: 75%;
}

.progressbar-benevolent-4 {
  width: 100%;
}

.progressbar-bg-info-container {
  margin-top: 10px;
  text-align: center;
  font-size: 0.8rem;
}

.all-objectives-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.objectives-container {
  margin: 20px;
}

.objectives-input {
  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 55px;
  margin: 10px;
  padding: 5px;
  font-size: 0.8rem;
  background-color: var(--secondary);
  border-radius: 20px;
  box-shadow: 0px 3px 3px var(--quarter-black);
  color: var(--off-black);
  transition: 0.1s linear;
}

.active-objective {
  background-color: var(--primary);
  color: var(--off-white);
}

.objectives-input:hover {
  background-color: var(--third);
  color: var(--off-white);
  transform: translateY(3px);
}

.objectives_on_request_info {
  font-size: 0.8rem;
}

.app-form-4-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-form-4-btns {
  display: flex;
  text-align: center;
}

.recall-text-info {
  margin: 10px;
  text-align: center;
  font-size: 1.2rem;
}

.exchange-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary);
  border: 1px solid var(--super-light-grey);
  border-radius: var(--bs-border-radius);
  color: var(--black);
  height: 40px;
  line-height: 40px;
  width: 80px;
  margin: 10px;
  transition: 0.1s linear;
}

.exchange-button-active {
  color: var(--off-white);
  background-color: var(--primary);
}

.exchange-button:hover {
  transform: translateY(3px);
  background-color: var(--third);
}

.app-interested-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-form-4-interested {
  margin-top: 10px;
  color: var(--primary);
}

.app-form-6 {
  text-align: center;
}

.app-form-6-table {
  width: var(--half-width);
  margin: 0 auto;
  text-align: left;
}

.app-form-6-table tr {
  display: flex;
}

.app-form-6-table td {
  padding: 10px;
  margin: 10px !important;
  background-color: var(--off-white);
  border-radius: 10px;
  box-shadow: 0px 0px 3px var(--quarter-black);
  flex: 1;
}

.table-remove-td {
  display: none;
}

.app-form-7 {
  margin-top: 0;
  padding: 20px;
  background-color: var(--white);
  text-align: center;
}

.app-form-7 p {
  color: var(--primary)
}

.conditions-app-form {
  display: flex;
  align-items: flex-start;
  width: var(--half-width);
  margin: 0 auto;
  padding-top: 0;
  text-align: left;
}

.conditions-app-form input[type=checkbox] {
  margin-top: 5px;
  margin-right: 5px;
}

.app-profile-date-input {
  background-color: var(--white);
  color: var(--black);
}

.app-profile-date-input-wrong {
  background-color: var(--input-wrong-color);
  color: var(--white);
  font-weight: bold;
}

.app-profile-date-info {
  margin-top: 10px;
}

.app-profile-date-info-correct {
  color: var(--primary);
  font-weight: 600;
}

.app-profile-date-info-wrong {
  color: var(--forth);
  font-weight: bold;
}

.app-profile-date-time-info {
  margin-top: 10px;
  color: var(--forth);
  font-weight: bold;
}

.shortcut-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  width: 100vw;
  min-height: 100dvh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

.shortcut-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50%;
  text-align: center;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 4px black;
}

.shortcut-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  font-size: x-large;
  color: black;
}

.shortcut-modal-ok {
  padding: 10px 20px;
  background-color: var(--third);
  border-radius: 10px;
  color: var(--white);
  font-size: x-large;
  font-weight: bold;
}

.shortcut-modal-hide {
  display: relative;
  width: 100%;
  margin-top: 20px;
  color: var(--primary);
  font-size: small;
  text-align: left;
  left: 0px;
  background-color: transparent;
  border: none;
}


/***** Wide screen  */
@media screen and (min-width: 1200px) {
  .app-form-select {
    margin-bottom: 20px;
  }

  .AppForm .conditions-app-form {
    margin: 20px auto;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .objectives-input {
    width: 35vw;
  }

  .app-profile-input-row {
    padding: 0;
  }

  .app-form-select {
    margin-bottom: 20px;
  }

  .app-form-6-table {
    width: 100%;
  }

  .AppForm .conditions-app-form {
    width: 100%;
    margin: 20px auto;
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 769px) {
  .app-container {
    height: 75vh;
  }

  .objectives-input {
    width: 35vw;
    height: 70px;
  }

  .app-profile-input-row {
    padding: 0;
  }

  .app-form-select {
    margin-bottom: 20px;
  }

  .app-form-6-table {
    width: 100%;
  }

  .AppForm .conditions-app-form {
    width: auto;
    margin: 20px auto;
  }
}



/***** Mobile screen  */
@media screen and (max-width: 480px) {
  /* .AppHome {
    height: var(--full-height);
  } */

  .AppForm h2 {
    font-size: 1.1rem;
  }

  .app-form-header-return-btn-text {
    display: none;
  }

  .app-container {
    display: flex;
    flex-direction: column !important;
    /* height: 65vh; */
  }

  .app-button-container {
    width: 200px;
    height: 200px;
  }

  .objectives-input {
    width: 100%;
    height: 55px;
    margin: 10px auto;
    font-size: 0.9rem !important;
  }

  .app-profile-input-row {
    flex-direction: column;
    width: 100%;
  }

  .app-profile-input-group {
    width: 100%;
    margin: 10px auto;
  }

  .app-form-select {
    margin-bottom: 20px;
  }

  .AppForm .conditions-app-form {
    margin: 20px auto;
  }
}