.home-help-card {
  cursor: pointer;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border-radius: 20px;
}

.home-help-card-subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
}

.home-help-card-1 {
  background-color: var(--secondary);
}

.home-help-card-2 {
  background-color: var(--primary);
}

.home-help-card-3 {
  background-color: var(--third);
}

.home-help-card-4 {
  background-color: var(--forth);
}

.home-help-card-image {
  width: 50%;
}

.home-help-card:hover {
  box-shadow: 0px 0px 1px var(--half-black);
  transform: translateY(10px);
}

.home-help-card-price {
  font-weight: bold;
}

.home-help-card-price-container {
  font-size: 3rem;
}

.home-help-card-free {
  font-size: 2.5rem;
}



/***** Wide screen  */
@media screen and (min-width: 1024px) {
  .home-help-card {
    height: 400px;
    margin: 10px;
    width: 280px;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .home-help-card {
    height: 350px;
    margin: 20px;
    width: 300px;
  }

  .home-help-card-price-container {
    font-size: 2.4rem !important;
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .home-help-card {
    height: 250px;
    margin: 10px;
    width: 250px;
  }

  .home-help-card-image {
    display: none;
  }

  .home-help-card-subtitle {
    font-size: 1.2rem;
  }

  .home-help-card-price-container {
    font-size: 2rem !important;
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .home-help-card {
    height: 130px;
    margin: 10px;
    width: 130px;
  }

  .home-help-card-image {
    display: none;
  }

  .home-help-card-subtitle {
    font-size: 1rem;
  }

  .home-help-card-price-container {
    font-size: 1.7rem !important;
  }

  .home-help-card-price-container .home-help-card-free {
    font-size: 1.5rem;
  }
}