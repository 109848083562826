.error-page-container {
  height: var(--full-height);
  width: var(--full-width);

}

.error-page-image-container {
  width: var(--half-width);
  margin: 0px auto;
  text-align: center;
}

.error-page-image-container>img {
  width: 80%;
}