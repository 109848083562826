.JoinUsForm {
  padding-top: 0 !important;
}

.join-us-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: var(--nineth-height);
  border-radius: 20px;
}

.join-us-form-header-image {
  width: 400px;
  margin: 0 auto;
}

.join-us-form-header-image img {
  width: 100%
}

.join-us-form-top-page-indicator {
  position: absolute;
  z-index: 1000;
  right: 30px;
  top: 30px;
  color: var(--light-grey)
}

.join-us-form-global {
  position: relative;
  z-index: 10;
  width: var(--eight-width);
  margin: 0 auto;
  overflow: hidden;
}

.join-us-form-container {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
}

.join-us-profile-input-row {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.join-us-profile-input-column {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  text-align: center;
}

.join-us-profile-input-group {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 10px;
}

.join-us-profile-input-group-full {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.join-us-profile-radio-group-full {
  padding: 10px;
  border: none;
}

.join-us-profile-radio-group-full input[type="radio"] {
  display: none;
}

.join-us-profile-input-row-radio {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  width: 50%;
  margin: 0 auto;
  justify-content: space-between;
}

.join-us-profile-input-row-radio label {
  cursor: pointer;
}


.join-us-profile-radio-group-element {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  padding: 10px !important;
  margin: 0 10px;
  width: 150px;
  transition: 0.1s linear;
}

.join-us-profile-radio-group-element-active {
  background-color: var(--third);
  color: var(--white);
}

.join-us-profile-input-group-full input {
  padding: 10px;
  height: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 2px var(--quarter-black);
}

.join-us-profile-input-group input,
.join-us-profile-input-group select {
  padding: 10px;
  height: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 2px var(--quarter-black);
}

.join-form-summary-table {
  width: var(--half-width);
  margin: 0 auto;
  text-align: left;
}

.join-form-summary-table tr {
  display: flex;
}

.join-form-summary-table td {
  padding: 10px;
  margin: 10px !important;
  background-color: var(--off-white);
  border-radius: 10px;
  box-shadow: 0px 0px 3px var(--quarter-black);
  flex: 1;
}

.join-us-summary-title {
  background-color: var(--white) !important;
  box-shadow: none !important;
  ;
}

/***** Wide screen  */
@media screen and (min-width: 1200px) {
  .join-us-content-container {
    padding: 10px 20px;
    width: 80%;
    margin: 0 auto;
  }

  .join-us-dates-container {
    width: 50%;
    margin: 0 auto;
  }

  .join-us-text-container {
    margin: 20px auto !important;
    align-items: center !important;
  }

  .join-us-textarea-container,
  .join-us-textarea-container>textarea {
    margin: 1px 0px;
  }

  .join-us-has-convention-container {
    text-align: center;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 901px) and (max-width: 1200px) {
  .JoinUsForm {
    margin-top: 20px !important
  }

  .JoinUsForm .join-us-container {
    margin-top: 100px;
  }

  .join-us-content-container {
    width: 100%;
    margin: 0 auto;
  }

  .join-us-dates-container {
    margin: 0 auto;
    display: flex;
    width: 100% !important;
    flex-direction: column !important;
  }

  .join-us-date-container {
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
  }

  .join-us-date-container>label {
    padding: 10px;
  }

  .join-us-text-container {
    margin: 20px auto !important;
    align-items: center !important;
  }

  .join-us-has-convention-container {
    text-align: center;
  }

}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .JoinUsForm {
    margin-top: 20px !important
  }

  .JoinUsForm .join-us-container {
    margin-top: 60px;
  }

  .join-us-form-header-image {
    width: auto;
  }

  .join-us-profile-input-row {
    padding: 0;
  }

  .join-us-profile-input-row-radio {
    padding: 0;
    width: 100%;
  }

  .progressbar-bg {
    margin: 10px auto;
  }

  .progressbar-bg-info-container {
    margin-top: 10px;
  }

  .join-us-content-container {
    width: 100%;
    margin: 0 auto;
  }

  .join-us-dates-container {
    margin: 0 auto;
    display: flex;
    width: 100% !important;
    flex-direction: column !important;
  }

  .join-us-date-container {
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
  }

  .join-us-date-container>label {
    padding: 10px;
  }

  .join-us-text-container {
    margin: 20px auto !important;
    align-items: center !important;
  }

  .join-us-textarea-container,
  .join-us-textarea-container>textarea {
    margin: 1px 0px;
  }

  .join-us-has-convention-container {
    text-align: center;
  }
}



/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .JoinUsForm {
    margin-top: 20px !important
  }

  .JoinUsForm .join-us-container {
    margin-top: 60px;
  }

  .join-us-form-header-image {
    width: auto;
  }

  .join-us-profile-input-row {
    padding: 0;
  }

  .join-us-profile-input-row-radio {
    padding: 0;
    width: 100%;
  }

  .join-us-profile-radio-group-full {
    padding: 10px 0px;
  }

  .buttons-container {
    width: 100%;
  }

  .progressbar-bg {
    width: 100%;
  }

  .progressbar-bg-info-container {
    margin-top: 20px;
  }

  .join-us-content-container {
    width: 100%;
    margin: 0 auto;
  }

  .join-us-dates-container {
    margin: 0 auto;
    display: flex;
    width: 100% !important;
    flex-direction: column !important;
  }

  .join-us-date-container {
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
  }

  .join-us-date-container>label {
    padding: 10px;
  }

  .join-us-text-container {
    margin: 20px auto !important;
    align-items: center !important;
  }

  .join-us-has-convention-container {
    text-align: center;
  }

  .conditions-app-form {
    width: 90%;
    margin-bottom: 20px;
  }
}