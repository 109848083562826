/***** Requests list page  */
.AdminRequests {
  display: flex;
  flex: 1;
  min-height: var(--full-height);
}

.admin-requests-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 30px;
}

.admin-requests-controllers,
.admin-requests-controllers-select {
  border-radius: 10px;
  border: 1px solid var(--medium-grey);
  background-color: var(--white);
  margin: 0 5px;
  padding: 5px 10px;
}

.admin-requests-controllers-active {
  background-color: var(--primary);
  color: var(--white);
}


/***** News list page  */

.admin-news-modal-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: var(--eight-black);
  width: 100%;
  height: 100%;
}

.admin-news-modal-inputs-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  width: 50vw;
  height: 50vh;
  padding: 20px;
}

.admin-news-modal-button {
  padding: 10px;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  border: none;
  margin: 20px auto;
  text-align: center;
  width: 120px;
}

.admin-news-lists {
  display: flex;
  flex-wrap: wrap;
}

.admin-news-modal-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid var(--extra-light-grey);
  background-color: var(--white);
  transition: 0.1s linear;
}

.admin-news-modal-close-button:hover {
  background-color: var(--black);
  color: var(--white);
}


/***** News list page  */
.admin-news-tsf-modal-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.admin-news-tsf-modal-layout {
  position: absolute;
  z-index: 1500;
  top: 0;
  left: 0;
  background-color: var(--eight-black);
  width: 100%;
  height: 200vh;
}


.admin-news-tsf-modal-inputs-container {
  position: relative;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  width: 50vw;
  min-height: 70vh;
  padding: 20px;
  margin-top: 50px;
}

.admin-news-tsf-modal-input-container {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 80%;
}

.admin-news-tsf-modal-input-container>label {
  margin-bottom: 10px;
}

.admin-news-tsf-modal-input-container>input {
  padding: 5px;
}

.admin-news-tsf-modal-textarea {
  min-height: 200px;
}

.admin-news-tsf-modal-content {
  color: var(--primary);
  background-color: 'blue' !important;
  min-height: 300px;
  border: 1px solid var(--extra-light-grey);
  overflow-y: auto;
}

.admin-news-tsf-modal-button {
  padding: 10px;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  border: none;
  margin: 20px auto;
  text-align: center;
  width: 120px;
}

.admin-news-tsf-lists {
  display: flex;
  flex-wrap: wrap;
}

.admin-news-tsf-modal-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid var(--extra-light-grey);
  background-color: var(--white);
  transition: 0.1s linear;
}

.admin-news-tsf-modal-close-button:hover {
  background-color: var(--black);
  color: var(--white);
}

.admin-news-tsf-modal-help-button {
  background-color: transparent;
  border: none;
  text-align: left;
  color: blue;
  font-size: small;
  font-weight: bold;
}

.admin-news-tsf-modal-help-text {
  color: var(--forth);
  font-weight: bold;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: left;
}

.admin-news-tsf-modal-link {
  color: var(--white) !important;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid black;
  padding: 5px 10px;
  width: 100%;
  background-color: var(--third);
  border-radius: 10px;
  text-align: center;
  transition: 0.1s linear;
  box-shadow: 2px 2px 3px var(--half-black);
}

.admin-news-tsf-modal-link:hover {
  transform: translateY(3px);
  box-shadow: none;
}

/******* Detailed request card  */
.AdminRequestDetailed {
  padding: 30px;
}

.admin-return-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: var(--off-white);
  border: 1px solid var(--medium-grey);
  border-radius: 10px;
}

.admin-request-card-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 10px auto;
  color: black;
}

.admin-request-card-row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 10px;
}

.admin-request-card-row-element {
  padding: 10px;
  margin: 0 10px;
  width: 400px;
  border: 1px solid var(--medium-grey);
  border-radius: 5px;
}

.admin-request-card-row-message {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

.admin-request-card-row-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-request-card-button {
  padding: 10px;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  border: none;
  margin: 20px auto;
  text-align: center;
}

.admin-request-card-button:visited {
  color: var(--white);
}