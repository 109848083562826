.news-content-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}

.news-button-container {
  text-align: center;
}

.news-button {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--off-white);
  background-color: var(--primary);
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 2rem;
  transition: 0.1s linear;
}

.news-button:hover {
  background-color: var(--forth);
}