.members-container {
  display: flex;
  flex-direction: column;
}

.members-top-image {
  width: 200px;
  margin: 10px auto;
}

.members-top-image img {
  width: 100%;
}

.members-benevolents-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.members-benevolents-list {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 10px;
}

.benevolent-members-card-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 0px 30px 10px var(--ten-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 5000;
  border-radius: 20px;
}

.benevolent-members-card-details-close-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  color: var(--white);
  cursor: pointer;
  border: none;
  background-color: var(--black);
  border-radius: 50%;
}

.benevolent-members-card-details-content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  width: 90%;
  text-align: center;
}

.benevolent-members-card-details-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 10px
}

.benevolent-members-card-details-title {
  font-size: 1.5rem;
  margin: 10px 0;
  color: var(--black);
}

.benevolent-members-card-details-occupation {
  font-size: 1rem;
  color: var(--black);
}

.members-join-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.members-join-button {
  background-color: var(--green);
  color: var(--white) !important;
  padding: 10px 20px;
  border-radius: 15px;
  width: 150px;
}

.members-join-button:hover {
  background-color: var(--third);
}