.LateralSidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-evenly;
  align-items: center;
  top: 20vh;
  right: 0;
  z-index: 1000;
  height: 150px;
  width: 35px;
  background-color: var(--ten-black);
  border-radius: 10px 0px 0px 10px;
  transition: 0.1s linear;
}

.LateralSidebar:hover {
  background-color: var(--white);
  box-shadow: -1px 0px 3px 0px var(--eight-black);
}

.lateral-sidebar-element {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: var(--quarter-black);
  border: none;
  color: var(--white) !important;
  border-radius: 20px;
  transition: 0.1s linear;
  cursor: pointer;
}

.LateralSidebar:hover .lateral-sidebar-element-mail {
  background-color: var(--primary);
  box-shadow: 1px 1px 3px var(--off-black);
}

.LateralSidebar:hover .lateral-sidebar-element-linkedin,
.LateralSidebar:hover .lateral-sidebar-element-instagram {
  background-color: var(--third);
  box-shadow: 1px 1px 3px var(--off-black);
}

.LateralSidebar:hover .lateral-sidebar-element-facebook {
  background-color: var(--forth);
  box-shadow: 1px 1px 3px var(--off-black);
}

.lateral-sidebar-element:hover {
  box-shadow: none !important;
  transform: translateY(3px);
}