.artivistes-container {
  max-width: 100vw;
  min-height: 80vh !important;
}

.artivistes-content {
  position: relative;
  display: flex;
  flex-direction: column;
}

.artivistes-image-container {
  margin: 0 auto;
  text-align: center;
}

.artivistes-image-container img {
  width: 50%;
  text-align: center;
}

.artivistes-button {
  display: flex;
  justify-content: center;
  bottom: 10px;
  width: 100%;
  margin: 0 auto;
}

/***** Wide screen  */
@media screen and (min-width: 1200px) {
  .artivistes-content {
    width: 70%;
    margin: 0 auto;
  }

  .artivistes-image-container img {
    width: 80%;
  }

  .Artivistes .artivistes-container .artivistes-button {
    position: relative;
    margin-top: 50px;
  }
}

/***** Desktop screen  */
@media screen and (min-width: 901px) and (max-width: 1200px) {
  .artivistes-content {
    width: 60%;
    margin: 0 auto;
    height: auto;
  }

  .artivistes-image-container {
    width: 60%;
  }

  .artivistes-image-container img {
    width: 90%;
  }

  .Artivistes .artivistes-container .artivistes-button {
    position: relative;
    margin-top: 50px;
  }
}

/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 900px) {
  .artivistes-content {
    width: 70%;
    margin: 0 auto;
  }

  .artivistes-image-container img {
    width: 100%;
  }

  .Artivistes .artivistes-container .artivistes-button {
    position: relative;
    margin-top: 20px;
  }
}

/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .artivistes-content {
    min-height: auto;
    height: auto;
  }

  .Artivistes .artivistes-container .artivistes-button {
    position: relative;
    margin-top: 30px;
  }
}