.HomePresentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.HomePresentation .home-presentation-container {
  padding: 20px;
}

.home-presentation-container h2 {
  margin-bottom: 30px;
}

.home-presentation-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.home-presentation-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40vw;
}

.home-presentation-carousel img {
  width: 80%;
  object-fit: contain;
}


/***** Wide screen  */
@media screen and (min-width: 1024px) {

  .HomePresentation {
    min-height: auto !important;
    height: auto !important;
  }

  .home-presentation-container {
    min-height: auto !important;
    height: auto !important;
  }

  .home-presentation-text,
  .home-presentation-text-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-wrap: wrap;
    padding: 0px 20px;
    width: 50vw;
    font-size: 1.3rem;
    line-height: 2rem;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .HomePresentation {
    min-height: auto !important;
    height: auto !important;
  }

  .home-presentation-container {
    min-height: auto !important;
    height: auto !important;
    padding-top: 20px !important;
  }

  .home-presentation-carousel {
    width: 50%;
    margin: 0 auto;
  }

  .home-presentation-text {
    position: relative;
    text-wrap: wrap;
    padding: 0px;
    width: 100%;
    flex: 1;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .home-presentation-text-2 {
    padding: 0px 10px;
    line-height: 1.8rem;
    font-size: 1.2rem;
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .HomePresentation {
    min-height: auto !important;
    height: auto !important;
  }

  .home-presentation-container {
    min-height: auto !important;
    height: auto !important;
    padding-top: 20px !important;
  }

  .home-presentation-content {
    flex-direction: column;
  }

  .home-presentation-text,
  .home-presentation-text-2 {
    padding: 20px 10px;
    margin-bottom: 10px;
    width: 100%;
    flex: 1;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .home-presentation-text-2 {
    margin-top: 15px;
  }
}


/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .HomePresentation {
    min-height: auto !important;
    height: auto !important;
  }

  .HomePresentation .home-presentation-container {
    padding: 20px;
    min-height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }

  .home-presentation-content {
    flex-direction: column;
  }

  .home-presentation-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 70vw;
  }

  .home-presentation-carousel img {
    width: 100%;
    object-fit: contain;
    margin-bottom: 20px
  }

  .home-presentation-text,
  .home-presentation-text-2 {
    padding: 0px 10px;
    width: 100%;
    flex: 1;
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .home-presentation-text {
    text-align: justify;
    line-height: 30px;
  }

  .home-presentation-text-2 {
    margin-top: 15px;
  }
}