.work-in-progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.work-in-progress-image {
  width: 400px;
}

.work-in-progress-image>img {
  width: 100%;
}