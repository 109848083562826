.HomeHelp {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.home-help-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.HomeHelp h2 {
  color: var(--forth)
}

.home-help-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

.home-help-header-title {
  display: flex;
  font-size: 1.8rem;
  margin: 0;
  margin-right: 20px;
}

.home-help-header-buttons-container {
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 3px var(--half-black);
  background-color: var(--off-white);
}

.home-help-buttons-decoration {
  position: absolute;
  z-index: 10;
  width: 50%;
  height: 100%;
  background-color: var(--primary);
  border-radius: 20px;
}

.home-help-buttons-decoration-left {
  transform: translateX(0%);
  transition: 0.3s ease-in-out;
  box-shadow: 0px 0px 3px black;
}

.home-help-buttons-decoration-right {
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
  box-shadow: 0px 0px 3px black;
}

.home-help-buttons-decoration-default {
  background-color: transparent;
}

.home-help-buttons-decoration-default-decoration {
  display: inline-flex;
  width: 1px;
  height: 14px;
  background-color: var(--extra-light-grey);
}

.home-help-button {
  position: relative;
  z-index: 50;
  border: none;
  color: var(--off-black);
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.5rem;
}

.home-help-button-min {
  position: relative;
  z-index: 50;
  border: none;
  color: var(--off-black);
  background-color: var(--extra-light-grey);
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.5rem;
  margin: 5px 0;
}

.home-help-button-active {
  color: var(--off-white);
  transition: 0.3s linear;
}

.home-help-button-min-active {
  color: var(--off-white);
  background-color: var(--primary);
  transition: 0.3s linear;
}

.home-help-content-list {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.home-help-content-list a {
  text-decoration: none;
}


/***** Wide screen  */
@media screen and (min-width: 1024px) {
  .home-help-content-list {
    width: 100%;
  }
}


/***** Desktop screen  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .home-help-content-list {
    width: 100%;
  }
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .home-help-container {
    padding: 0px !important;
  }
}


/***** Mobile screen  */
@media screen and (min-width: 375px) and (max-width: 480px) {
  .HomeHelp {
    min-height: auto !important;
    height: auto !important;
  }

  .HomeHelp .home-help-container {
    min-height: auto !important;
    height: auto !important;
  }

  .home-help-header-container {
    flex-direction: column;
  }

  .home-help-header-title {
    margin-bottom: 10px;
  }

  .home-help-content-list {
    width: 100vw;
  }
}

/***** Mobile screen  */
@media screen and (max-width: 375px) {
  .HomeHelp {
    min-height: auto !important;
    height: auto !important;
  }

  .HomeHelp .home-help-container {
    min-height: auto !important;
    height: auto !important;
  }

  .home-help-header-container {
    flex-direction: column;
  }

  .home-help-header-title {
    margin-bottom: 10px;
  }

  .home-help-header-buttons-min-container {
    display: flex;
    flex-direction: column;
  }

  .home-help-content-list {
    width: 100vw;
  }
}