.become-home-container {
  height: var(--eight-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.become-home-container h1 {
  margin-bottom: 40px;
}

.become-home-cards-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


/***** Tablet screen  */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .become-home-cards-container {
    flex-direction: column;
  }
}

/***** Mobile screen  */
@media screen and (max-width: 480px) {
  .become-home-cards-container {
    flex-direction: column;
  }
}