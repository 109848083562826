.global-app-form {
  position: relative;
  z-index: 10;
  width: var(--full-width);
  margin: 0 auto;
  overflow: hidden;
}

.global-app-form a {
  text-decoration: none;
}