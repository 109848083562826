:root {
  --text-color: #959DA5;
  --footer-bg: #0D1117;
  --footer-bottom-bg: #161B22;
  --white: white;
  --link-color: #959DA5;
  --link-hover: #2F81CF;
  --button-border: #848D97;
}

/*? footer containers */
footer {
  bottom: 0px;
  background-color: var(--footer-bg);
  width: 100%;
  min-width: 300px;
  bottom: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.footer-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
}

.footer-logo-columns {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 4;
  gap: 48px 16px;
}

.footer-logo {
  margin-bottom: 2rem;
  width: 3rem;
}

.footer-logo-column {
  display: flex;
  grid-area: footer-logo;
  flex-direction: column;
  min-width: 16rem;
  flex-grow: 1;
  padding-right: 0.5rem;
}

.footer-logo-column p {
  color: var(--text-color);
  font-size: 0.95rem;
  margin-bottom: 1.5rem;
}

.footer-logo-column h3 {
  color: var(--white);
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

/* other columns */


.other-columns {
  width: 100%;
  gap: 1rem;
  row-gap: 2rem;
  padding-top: 2rem;
}

.other-columns ul {
  display: flex;
  gap: 0.75rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
  font-weight: 600;
}

.other-columns ul a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 0.85rem;
}

.other-columns ul a:hover {
  text-decoration: underline;
}

.other-columns h3 {
  color: var(--link-color);
  margin-bottom: 1rem;
  font-size: 1rem;
  font-family: ui-monospace, monospace;
}

.footer-logo svg {
  fill: var(--white);
}

/*? Footer bottom */
.footer-bottom {
  width: 100%;
  padding: 1rem 0px;
  color: var(--text-color);
  background-color: var(--footer-bottom-bg);
}

.footer-bottom-wrapper {
  display: flex;
  max-width: 1024px;
  margin: 0 auto;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 1rem;
}

.footer-bottom small {
  font-size: 1rem;
  display: inline;
  white-space: nowrap;
}

.footer-bottom-links {
  list-style-type: none;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.footer-bottom-links a {
  color: var(--link-color);
  text-decoration: none;
  white-space: nowrap;
}

.footer-bottom-links a:hover {
  color: var(--link-hover);
  text-decoration: underline;
}

.footer-bottom>small {
  font-size: 1rem;
  margin: 0 auto;
  width: 100%
}

/*? socials */
.social-wrapper {
  display: flex;
  gap: 0.5rem;
  margin: 0 auto;
}

.social-links {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

.social-links img {
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.2s ease-in-out;
}

.social-links img:hover {
  transform: scale(1.1);
}

.social-links ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  list-style-type: none;
}

.footer-bottom-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


@media (min-width: 320px) {
  .other-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .other-columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .footer-bottom-wrapper {
    flex-direction: row;
  }

  .footer-bottom-links {
    margin-left: 1.5rem;
  }
}

@media (max-width: 800px) {
  .footer-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem 0.5rem 2rem 0.5rem;
  }
}