:root {

  /*** Colors */
  /* Primary colors */
  --beige: #fff6eb;
  --black: #000;
  --dark-grey: #292c30;
  --forth: #E7093D;
  --extra-light-grey: #e3e3e3;
  --green: #008000;
  --input-wrong-color: #b51409;
  --light-green: rgba(0, 161, 19, 0.3);
  --light-grey: #686a6d;
  --light-orange: rgba(255, 123, 0, 0.3);
  --medium-grey: #3a3c3f;
  --off-black: #0c0d0e;
  --off-grey: #333;
  --off-white: #FAF9F6;
  --primary: #004cff;
  --secondary: rgb(191, 211, 251);
  --super-light-grey: #a8a7a7;
  --third: #F59C2E;
  --tsf-grey: #E6E6E6;
  --white: #fff;

  /* Opacity colors */
  --divider: rgba(0, 0, 0, 0.5);
  --divider-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  --eight-black: rgba(0, 0, 0, 0.8);
  --eight-white: rgba(255, 255, 255, 0.8);
  --fifteen-black: rgba(0, 0, 0, 0.15);
  --fifteen-white: rgba(255, 255, 255, 0.15);
  --half-black: rgba(0, 0, 0, 0.4);
  --half-white: rgba(255, 255, 255, 0.4);
  --modal-bg: rgba(0, 0, 0, 0.8);
  --nineth-black: rgba(0, 0, 0, 0.9);
  --nineth-white: rgba(255, 255, 255, 0.9);
  --quarter-black: rgba(0, 0, 0, 0.25);
  --quarter-white: rgba(255, 255, 255, 0.25);
  --ten-black: rgba(0, 0, 0, 0.10);
  --ten-white: rgba(255, 255, 255, 0.10);

  /*** Sizes */
  /* Home */
  --home-title-size: 5rem;

  /* Navbar */
  --navbar-icon-size: 1.2rem;
  --navbar-label-size: 0.6rem;

  /*** Dimensions */
  /* Navbar */
  --eight-height: 80vh;
  --eight-width: 80vw;
  --full-height: 100vh;
  --full-padded-height: 96vh;
  --full-padded-width: 96vw;
  --full-width: 100vw;
  --half-height: 50vh;
  --half-width: 50vw;
  --navbar-desktop-height: 60px;
  --navbar-mobile-height: 50px;
  --nineth-height: 90vh;
  --nineth-width: 90vw;
  --quarter-width: 25vw;
  --quarter-height: 25vh;
  --seventh-height: 70vh;
  --seventh-width: 70vw;
  --sixth-height: 60vh;
  --sixth-width: 60vw;

  /*** Paddings */
  --padding-bottom-mobile: 60px;
  --padded-mobile-screen: 10px;
}

h1,
h2,
h3,
h4,
h5 {
  text-align: center;
  font-family: Scripter;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: var(--off-black)
}

.tsf-global-screen {
  margin-top: calc(var(--navbar-desktop-height) + 30px);
  width: var(--full-width);
  max-width: var(--full-width);
  min-height: var(--full-height);
  overflow-x: hidden;
  /* background-image: url("../assets/images/TSF/tsf-bg.png"); */
  padding-top: 20px;
}

.tsf-global-screen-wb {
  background-image: url("../assets/images/TSF/tsf-bg.png");
}

.tsf-content-container {
  background-color: var(--white);
  width: var(--nineth-width);
  margin: 20px auto;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 20px;
}

.tsf-home-page-wb {
  width: var(--full-width);
  max-width: var(--full-width);
  min-height: var(--nineth-height);
  overflow-x: hidden;
  background-image: url("../assets/images/TSF/tsf-bg.png");
  padding: 20px;
}

.tsf-home-page-nb {
  width: var(--full-width);
  max-width: var(--full-width);
  min-height: var(--nineth-height);
  overflow-x: hidden;
  background-color: var(--white);
  padding: 20px;
}

.tsf-home-page-content {
  background-color: var(--white);
  width: var(--nineth-width);
  min-height: var(--eight-height);
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px;
}

.tsf-home-page-content-auto {
  background-color: var(--white);
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px;
}

/* .tsf-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Scripter;
  text-transform: uppercase;
  font-size: inherit;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: 0.1s linear;
}

.tsf-button-primary {
  background-color: var(--primary);
  color: var(--white);
}

.tsf-button-third {
  background-color: var(--third);
  color: var(--white);
}

.tsf-button-forth {
  background-color: var(--forth);
  color: var(--white);
}

.tsf-button-shadow {
  transition: 0.1s linear;
  border: none;
  box-shadow: 2px 2px 3px var(--half-black);
}

.tsf-button-shadow:hover {
  transform: translateY(3px);
  box-shadow: none;
} */

.tsf-divider {
  width: 50vw;
  height: 2px;
  margin: 20px auto;
  background-color: var(--half-black);
  border-radius: 50px;
}

.tsf-small-divider {
  width: 20vw;
  height: 2px;
  margin: 10px auto;
  background-color: var(--quarter-black);
  border-radius: 50px;
}

.tsf-loader-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--eight-black);
}

@media screen and (max-width: 950px) {

  .tsf-global-screen {
    margin-top: 50px;
  }
}